import React, { useState } from 'react';
import Layout from './Layout';

function SignupForm() {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
    });

    const apiBaseUrl = window.location.hostname === "www.tradeparser.ai" ? "https://www.tradeparser.ai" : "http://localhost:4000";
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetch(`${apiBaseUrl}/signup`, { // Use dynamic base URL
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Handle success
                alert('Sign up successful!');
            } else {
                // Handle failure
                alert('Sign up failed. Please try again later.');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            // Handle network or server errors
            alert('An error occurred. Please try again later.');
        }); // Ensure this closing parenthesis is present
    };

    return (
        <Layout>
            <div className="container mx-auto p-5">
                <p className="mb-5">Contact us today for a personalized quote!</p>
                <div className="bg-gray-300 rounded-lg shadow-xl p-8 md:p-20 text-base">
                    <form onSubmit={handleSubmit} id="signup-form">
                        <div className="mb-4 flex items-center">
                            <label htmlFor="name" className="w-20">Name:</label>
                            <input 
                                type="text" 
                                id="name" 
                                name="name" 
                                required 
                                className="bg-gray-200 flex-grow" 
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="mb-4 flex items-center">
                            <label htmlFor="company" className="w-20">Company:</label>
                            <input 
                                type="text" 
                                id="company" 
                                name="company" 
                                required 
                                className="bg-gray-200 flex-grow" 
                                value={formData.company}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="mb-4 flex items-center">
                            <label htmlFor="email" className="w-20">Email:</label>
                            <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                required 
                                className="bg-gray-200 flex-grow" 
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="mb-4 flex items-center">
                            <label htmlFor="phone" className="w-20">Phone:</label>
                            <input 
                                type="tel" 
                                id="phone" 
                                name="phone" 
                                placeholder="Optional" 
                                className="bg-gray-200 flex-grow" 
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="pt-2">
                            <button type="submit" id="signup-button" className="bg-blue-500 hover:bg-blue-600 text-white py-3.5 px-6 rounded-lg text-xl">
                                Contact us
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default SignupForm;
