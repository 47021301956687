import React from 'react';
import Layout from './Layout';
import tradeParserDiagram from '../assets/tradeparserdiagram2.png';
import {Link} from 'react-router-dom';

function HomePage() {
  return (
    <Layout>
        <div className="container mx-auto p-2">
            <h1 className="Noto Color Emoji text-2xl md:text-4xl font-bold text-center">
                Automatically extracts trade values from unstructured emails
            </h1>
            <div className="text-center py-2 mb-5">
                <span className="artsy-highlight inline-block text-2xl md:text-4xl">
                    Save hours of boring work
                </span>
            </div>
            <div className="bg-gray-200 bg-opacity-20 rounded-lg shadow-xl px-5 pt-8 pb-5">
                Scans emails and attachments for values and automatically stores them in a database for easy access and analysis.
                <br></br><br></br>
                Our data extraction software is optimized for physical traders that work with <b>unstructured & ambigious</b> data sent by emails. 
                Covering a wide range of trading processes, such as processing quotations or settling quality measurements between supplier and customer.
                <br></br><br></br>
                We ensure <b>reliability</b> of extracted data by using a risk-based approach, flagging values of lower confidence for a quick spot check within the tool.
            </div>
            <div className="md:flex justify-between">
                <div className="bg-gray-200 bg-opacity-20 rounded-lg shadow-xl p-5 mb-7 mt-10 md:w-1/2">
                    <div className="flex-grow ml-3 mt-3">
                        <p><b>Simple</b> to set up:</p>
                        <ol className="list-decimal mb-5 pl-8 marker:font-bold">
                            <li>Auto forward emails</li>
                            <li>Describe the columns you want to retrieve</li>
                            <li>Provide examples (input to output)</li>
                            <li>Analyze data or connect to an ERP system</li>
                        </ol>
                    </div>
                    <div className="pt-2 mb-5 ml-3 w-3/4">
                        <img className="rounded-lg" src={tradeParserDiagram} alt="Manual Workflows" style={{ width: '100%', objectFit: 'contain' }} />
                    </div>
                </div>
                <div className="bg-gray-200 bg-opacity-20 rounded-lg shadow-xl p-5 mb-7 mt-10 md:ml-7 md:w-1/2">    
                    <div className="text-center mt-1 mb-1">
                        <h2 className="text-xl font-semibold">
                            Check out the demo below
                        </h2>
                        <p className="text-md">
                            And see <b>how</b> TradeParser can automate your manual work
                        </p>
                    </div>
                    <div className="my-4 px-5 mx-auto">
                        <iframe className="w-full aspect-video" src="https://www.youtube.com/embed/FRdB1hGCd20?si=GhCSkqQm_lJ0vnfc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div className="py-1 mb-10">
                    <Link to="/signup" className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3.5 px-6 rounded-full">
                        Try it now!
                    </Link>
            </div>
        </div>        
    </Layout>
  );
}

export default HomePage;
