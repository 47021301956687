import React, { useState } from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import manualWorkflows from '../assets/Manual-Workflows.png';

function BlogPage() {
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);

  const toggleBlog = () => {
    setIsExpanded1(!isExpanded1);
  };

  const toggleBlog2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  return (
    <Layout>
      <div className="container mx-auto p-5">
        <div className="bg-gray-200 bg-opacity-20 rounded-lg shadow-xl p-5 md:p-10">        
            <h1 className="text-xl md:text-2xl font-bold my-2 text-gray-600">Why automation has failed (so far)</h1>
            <p className="text-sm sm:text-xl leading-normal pt-1">Automation has only seen marginal successes so far, <strong>30 to 50% of initial Robotic ProcessAutomation (RPA) projects fail</strong> according to EY [0], even with large upfront investments. Existing parsing tools only work well if documents are consistently structured. Whereas in the real world, data is often sent in slightly different formats and styles for different reasons, such as: changes in processes/ systems, unstructured processes or ad hoc emails. Automation therefore ends up being <strong>too expensive or inaccurate</strong>. Therefore, instead of automating, companies throw more people at the problem, which doesn’t scale.</p>
            
            <div onClick={toggleBlog} className="cursor-pointer">
              <h2 className="text-blue-800 text-xl font-bold my-4">{isExpanded1 ? "Collapse blog post" : ""}</h2>
              {isExpanded1 ? (
                <div>
                  <p className="text-sm sm:text-xl leading-normal pt-2 pb-2">Another problem with automation is that if there's <strong>only one mistake</strong>, operators are going to revert back to manual data extraction. Also if the overall AI error rate is much smaller than the human error rate. Humans want to have control over their own mistakes and are likely to have a biased view regarding their own error rate.</p>
                  <p className="text-sm sm:text-xl leading-normal pt-2 pb-2">In order for automation to succeed, we need a level of <strong>flexibility to deal with unstructured data</strong> that goes beyond hard coded (rule-based) logic. In addition, we also need a level of logical thinking to decide which fields to use when there is ambiguity in the data. Recent advancements in large language models (LLM) provide us an opportunity to deal with the unstructured and ambiguous nature of real world data. With clear instructions and company specific examples, AI can act like a data entry agent and perform consistent output.</p>
                  <p className="text-sm sm:text-xl leading-normal pt-2 pb-2">Next, we need to approach data parsing more like a <strong>machine learning classification problem</strong>; where we predict a value and that prediction is either true or false. In classification, <strong>a confidence score</strong> is used to decide what action to take. Similarly, we can get a confidence score for parsed data and take action accordingly. That is, we can have a human manually review and validate parsed values when we have lower confidence. This is comparable to how operators might also need to follow up with the sender to clarify the data. We need to incorporate that part of the process in automation to ensure 100% accuracy in the parsed data.</p>
                  <p className="text-sm sm:text-xl leading-normal pt-2 pb-2">Our TradeParser AI solution is <strong>optimized for unstructured and ambiguous trading data</strong>, by using the latest state of the art AI and a confidence score for 100% accuracy. In addition, our solution vertically integrates with your existing processes based on customer specific instructions on what values to parse and a smart matching algorithm to match it with your company’s internal systems.</p>
                  <p className="text-sm sm:text-xl leading-normal pt-2 pb-2">Reach out <Link to="/signup" className="text-blue-800 underline font-bold"> here</Link> to see how we can help you save time.</p>
                  <p className="text-sm sm:text-xl font-bold leading-normal pt-3.5 "><Link to="/">Trade Parser AI</Link></p>
                  <p className="text-base leading-normal pt-5 pb-2">[0] <Link to="https://assets.ey.com/content/dam/ey-sites/ey-com/en_gl/topics/emeia-financial-services/ey-get-ready-for-robots.pdf">https://assets.ey.com/content/dam/ey-sites/ey-com/en_gl/topics/emeia-financial-services/ey-get-ready-for-robots.pdf</Link> </p>
                </div>
                  ) : (
                  <p className="text-xl font-semibold leading-normal">Click here to expand.</p>
                )}
              </div>
        </div> 
      </div>

      <div className="container mx-auto p-5">
        <div className="bg-gray-200 bg-opacity-20 rounded-lg shadow-xl p-5 md:p-10">        
            <h1 className="text-xl md:text-2xl font-bold my-2 text-gray-600">We Automated 80% of Incoming Emails for a Trading Company, Saving 20 Hours Per Week</h1>
            <p className="text-sm sm:text-xl leading-normal pt-1">Our TradeParser software solution was able to <strong>automate 80% of incoming settlement emails</strong> for a commodity trading company (with a path to 100%). This solution saved our client <strong>20 hours per week</strong> of boring, expensive, data entry work. They can now spend more time growing their business in a scalable way.</p>
            
            <div onClick={toggleBlog2} className="cursor-pointer">
              <h2 className="text-blue-800 text-xl font-bold my-4">{isExpanded2 ? "Collapse blog post" : ""}</h2>
              {isExpanded2 ? (
                <div>
                  <h3 className="text-xl sm:text-xl font-semibold my-2 pt-3.5">The Problem with Manual Data Entry and API’s</h3>
                  <p className="text-sm sm:text-xl leading-normal pt-0.5 flex-1">Many companies are spending a large percentage of their <strong>employee cost on manual data entry work</strong>, manually typing values from emails to their ERP system or local Excel files. Manual data entry only scales linearly and can therefore become expensive. It’s also demotivating work and prone to human errors (estimated between 1% and 4%).</p>
                  <div className="flex items-start pt-2">
                      <img src={manualWorkflows} alt="Manual Workflows" className="w-auto max-h-64" style={{ maxHeight: '250px', minHeight: '150px', objectFit: 'contain' }} />
                  </div>
                  <p className="text-sm sm:text-xl leading-normal pt-2">Automating data entry is not straightforward as it requires some level of judgment. Hard-coded solutions’ (like regex) performance are often suboptimal and don’t tend to stand the test of time. But with the recent developments of <strong>Large Language Models (LLMs)</strong> we now have the <strong>flexibility and logical thinking required</strong> to solve most recurring data entry jobs, with the right level of guidance.</p>
                  
                  <p className="text-sm sm:text-xl leading-normal pt-2">An alternative solution would be to integrate with customer ERP systems, which has been a large focus in the last decade. However, these solutions are capital intensive and therefore often not ROI positive for small and medium sized businesses. Especially companies that work with many different suppliers and customers of different sizes. It also creates dependencies on unknown ERP systems and the API connection could break when the ERP is not maintained or updated. Creating <strong>flexibility on the backend is therefore cheaper and easier to maintain</strong>.</p>
                  
                  <h3 className="text-xl sm:text-xl font-semibold my-2  pt-3.5">How the Automation Works</h3>
                  <p className="text-sm sm:text-xl leading-normal">Our client receives recurring and ad hoc emails from customers and suppliers for every shipped order, where every email can include multiple orders with their weight and relevant percentages. The client compares the values and calculates the price accordingly.</p>
                  
                  <p className="text-sm sm:text-xl leading-normal pt-3.5">We complete <strong>the following steps</strong>:</p>
                  <ol className="list-decimal pt-2 pl-8 marker:font-bold">
                      <li>Automatically retrieve data from email (Outlook with MS Graph)</li>
                      <li>Scan all the relevant text and attachments (pdf, excel, csv, image etc.) with advanced OCR scanning tools</li>
                      <li>Automatically connect the emails to the suppliers and their planned orders</li>
                      <li>Use ChatGPT’s API to retrieve the relevant fields, using supplier specific instructions and examples, to retrieve the right columns consistently.</li>
                      <li>With checks and balances based on historical values, we ensure 100% accuracy. We provide confidence levels for all values, where lower confidence levels require a manual review</li>
                      <li>Automatically merge the values to the planned orders in the internal database.</li>
                      <li>Provide monitoring to detect any potential performance deterioration.</li>
                  </ol>    
                  
                  <p className="text-sm sm:text-xl leading-normal pt-2">Note that the data provided to the ChatGPT API is never used for anything else other than providing the output, as stated in OpenAI’s API platform policy. </p>
                  
                  <h3 className="text-xl sm:text-xl font-semibold my-2 pt-3.5">Results</h3>
                  <p className="text-sm sm:text-xl leading-normal pt-1">Through this software solution, we were able to <strong>automate 80%</strong> of currently incoming emails. The remaining 20% will be further automated through software improvements and by educating suppliers to provide better data input (e.g. sometimes they don’t provide column headers in their tables).</p>
                  
                  <h3 className="text-xl sm:text-xl font-semibold my-2 pt-3.5">Contact Us</h3>
                  <p className="text-sm sm:text-xl leading-normal pt-1">Does this problem and solution resonate with your business? Do you want to learn more how we can help your business grow? <Link to="/signup" className="text-blue-800 underline font-bold">Please contact us</Link>.</p>    
                  <p className="text-sm sm:text-xl font-bold leading-normal pt-3.5 "><Link to="/">Trade Parser AI</Link></p>
                </div>
                  ) : (
                  <p className="text-xl font-semibold leading-normal">Click here to expand.</p>
                )}
              </div>
        </div> 
      </div>


    </Layout>
  );
}

export default BlogPage;
