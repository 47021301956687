import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

const DashboardLayout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Set class on body element specific to the Dashboard layout
    document.body.className = 'bg-dashboard-gray font-sans text-base';

    // Cleanup function
    return () => {
      document.body.className = '';
    };
  }, []);

  return (
    <div className="flex">
      <Helmet>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Dashboard - Trade Parser AI</title>
          {/* Reuse favicons and scripts from the provided layout */}
          <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.PUBLIC_URL}/apple-touch-icon.png`} />
          <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/favicon-32x32.png`} />
          <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/favicon-16x16.png`} />
          <link rel="manifest" href={`${process.env.PUBLIC_URL}/site.webmanifest`} />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-2194WW5B3T"></script>
          <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-2194WW5B3T');
              `}
          </script>
      </Helmet>
      <aside className="w-40" aria-label="Sidebar">
        <div className="mt-32 py-4 px-2">
          <ul className="text-center">
            <li>
              <Link to="/dashboard" className={`flex items-center justify-center py-3 text-base font-normal rounded-lg w-full ${location.pathname === '/dashboard' ? 'text-gray-900 font-semibold bg-gray-100 border shadow-lg' : 'text-gray-700 hover:text-gray-900'}`}>
                Summary
              </Link>
            </li>
            <li>
              <Link to="/manual_review" className={`flex items-center justify-center py-3 text-base font-normal rounded-lg mt-8 w-full ${location.pathname === '/manual_review' ? 'text-gray-900 font-semibold bg-gray-100 border shadow-lg' : 'text-gray-700 hover:text-gray-900'}`}>
                My tasks
              </Link>
            </li>
            <li>
              <Link to="/submitted_values" className={`flex items-center justify-center py-3 text-base font-normal rounded-lg mt-8 w-full ${location.pathname === '/submitted_values' ? 'text-gray-900 font-semibold bg-gray-100 border shadow-lg' : 'text-gray-700 hover:text-gray-900'}`}>
                Analyze results
              </Link>
            </li>
          </ul>
        </div>
      </aside>
      <main className="flex-1">
        {children}
      </main>
    </div>
  );
};

export default DashboardLayout;