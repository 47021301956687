import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { StateProvider } from './components/DashboardStateContext';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import BlogPage from './components/BlogPage';
import PricingPage from './components/PricingPage';
import SignupForm from './components/SignupForm';
import Dashboard from './components/Dashboard';
import ManualReview from './components/ManualReview';
import SubmittedValues from './components/SubmittedValues';

function App() {
  return (
    <StateProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/signup" element={<SignupForm />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/manual_review" element={<ManualReview />} />
            <Route path="/submitted_values" element={<SubmittedValues />} />
          </Routes>
        </div>
      </Router>
    </StateProvider>
  );
}

export default App;
