import React, { useState, useEffect } from 'react';
import DashboardLayout from './DashboardLayout';
import EmailPreview from './EmailPreview';
import '../css/output.css';

function ManualReview() {
  const [groupedData, setGroupedData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(() => parseInt(localStorage.getItem('currentIndex'), 10) || 0);
  const [totalEmailsToReview, setTotalEmailsToReview] = useState(() => parseInt(localStorage.getItem('totalEmailsToReview'), 10) || 0);
  const [emailsReviewed, setEmailsReviewed] = useState(() => parseInt(localStorage.getItem('emailsReviewed'), 10) || 0);
  const [attachmentPreview, setAttachmentPreview] = useState(null);

  const calculateUniqueEmailsToReview = async () => {
    try {
      const response = await fetch('http://localhost:4000/unique-emails-count');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('calculateUniqueEmailsToReview:', data.count);
      return data.count;
    } catch (error) {
      console.error('Error calculating unique emails to review:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchEmailValues = () => {
      fetch('http://localhost:4000/get-email-values')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          const initialData = data;
          if (!initialData || initialData.length === 0) {
            console.log('No data received or data is empty');
            return;
          }
          const groupedByEmail = groupByEmail(initialData);
          setCurrentIndex(0);
          localStorage.setItem('currentIndex', 0);
          setGroupedData(Object.values(groupedByEmail));
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });
    };
  
    fetchEmailValues(); // Fetch immediately on component mount or emailId change.
  
    const interval = setInterval(fetchEmailValues, 60000*10); // Fetch every 60 seconds.
  
    return () => clearInterval(interval); // Cleanup interval on component unmount or when dependencies change.
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount.  

  useEffect(() => {
    localStorage.setItem('currentIndex', currentIndex.toString());
  }, [currentIndex]);

  useEffect(() => {
    const initTotalEmailsToReview = async () => {
      if (!localStorage.getItem('totalEmailsToReview')) {
        try {
          const count = await calculateUniqueEmailsToReview();
          localStorage.setItem('totalEmailsToReview', String(count));
          console.log('set local storage:', count);
          setTotalEmailsToReview(count);
        } catch (error) {
          console.error("Error fetching initial count:", error);
        }
      } else {
        const storedCount = parseInt(localStorage.getItem('totalEmailsToReview'), 10);
        if (!isNaN(storedCount)) {
          setTotalEmailsToReview(storedCount);
        }
      }
    };
  
    initTotalEmailsToReview(); // Call immediately on component mount.
  
    const interval = setInterval(initTotalEmailsToReview, 60000*10); // Repeat every 10 minutes.
    return () => clearInterval(interval); // Cleanup interval on component unmount.
  
  }, [calculateUniqueEmailsToReview]); // Dependencies array includes the function because it might be defined outside of useEffect.  

  // Effect hook to update localStorage whenever emailsReviewed changes
  useEffect(() => {
    localStorage.setItem('emailsReviewed', emailsReviewed.toString());
  }, [emailsReviewed]);

  // Function to increment the count of reviewed emails
  const handleEmailReviewed = () => {
    setEmailsReviewed(prev => prev + 1);
  };

  const progressBarWidth = totalEmailsToReview > 0 ? Math.min((emailsReviewed / totalEmailsToReview) * 100,100) : 100;

  const groupByEmail = (data) => {
    // console.log('groupByEmail started, data:', data);
    return data.reduce((acc, row) => {
      const key = row.email_id;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(row);
      return acc;
    }, {});
  };

  const handleNext = () => {
    if (currentIndex < groupedData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
    }
  };

  const handleAction = (action) => {
    // Check for missing values before proceeding
    const isValueMissing = groupedData[currentIndex].some(row => !row.weight_kg || !row.dm_fat || !row.order_nr);
  
    if (action === 'delete') {
      if (!isValueMissing && !window.confirm("Email has values, are you sure you want to delete?")) {
        return; // Stop the deletion if the user cancels
      }
    } else if (action === 'submit') {
      if (isValueMissing && !window.confirm("Values missing for weight, DM/Fat and/or order nr, are you sure you want to submit?")) {
        return; // Stop the submission if the user cancels
      }
    }
  
    const statusToUpdate = action === 'delete' ? 'x' : 'ok';
    
    // update emails reviewed (if not stopped)
    if (action === 'delete' || action ==='submit') {
      handleEmailReviewed();
    }
    
    // Update the groupedData with the new status
    const updatedGroupData = groupedData[currentIndex].map(row => ({
      ...row,
      'status': statusToUpdate
    }));
  
    // Update the database with the new status for each row
    updatedGroupData.forEach(row => {
      fetch('http://localhost:4000/update-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // TODO: add all fields to update (mostly weight, dm/fat, order nr)!
        body: JSON.stringify({
          email_id: row.email_id,
          status: statusToUpdate,
        }),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        console.log('Row email_id:', row.email_id);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    });
  
    // Update the UI to reflect the change
    const newGroupedData = [...groupedData];
    newGroupedData[currentIndex] = updatedGroupData;
    setGroupedData(newGroupedData.filter(group => group.some(row => row['status'] !== statusToUpdate)));
    
    // index. If submit or delete, index stays the same (i.e. go to the next email). If it's the last email then index - 1 
    console.log('index logic:', currentIndex, newGroupedData.length - 1);
    if (currentIndex >= newGroupedData.length - 1 ) {
      // If we've run out of items or the current index is now out of bounds, adjust it
      setCurrentIndex(Math.max(currentIndex - 1, 0));
    }
  };

  const handleCellChange = (index, column, value) => {
    const newData = [...groupedData[currentIndex]];
    newData[index][column] = value;
    const newGroupedData = [...groupedData];
    newGroupedData[currentIndex] = newData;
    setGroupedData(newGroupedData);
  };

  const handleAddRow = (index) => {
    const baseRow = groupedData[currentIndex][index];
    const newRow = {
      ...baseRow,
      'action': '', 'weight_kg': '', 'dm_fat': '', 'protein': '', 'order_nr': '', 
      'contract_nr': '', 'date': '', 'week': '', 'product': '', 'values_confidence': ''
    };
    // Ensure only the specified columns are copied and the rest are initialized as empty
    const newData = [...groupedData[currentIndex]];
    newData.splice(index + 1, 0, newRow); // Insert newRow at specified index + 1
    const newGroupedData = [...groupedData];
    newGroupedData[currentIndex] = newData;
    setGroupedData(newGroupedData);
  };

  const handleDeleteRow = (index) => {
    const row = groupedData[currentIndex][index];
    
    // Convert row object to array of its values
    const values = Object.values(row);
  
    // check if there are any data in the columns after the email info columns
    const startIndex = 5; // Start checking after the email info columns
    const checkLength = 4; // Number of columns to check after email info columns
    const hasData = values.slice(startIndex, startIndex + checkLength).some(value => value !== '');
  
    // If there's data, confirm before deleting
    if (hasData && !window.confirm("Are you sure you want to delete this row?")) {
      return; // If user cancels, do nothing
    }
  
    // Proceed with deletion if the row is empty or the user confirmed
    const newData = [...groupedData[currentIndex]];
    newData.splice(index, 1);
    const newGroupedData = [...groupedData];
    newGroupedData[currentIndex] = newData;
    setGroupedData(newGroupedData);
  };

  const currentData = groupedData[currentIndex] || [];
  const noMoreEmails = currentData.length === 0 || currentIndex >= groupedData.length;
  const firstRow = currentData[0] || {};

  return (
    <DashboardLayout>
      <div className="mx-auto px-6">
        <h1 className="text-xl text-center text-blue-800 md:text-2xl font-bold pt-4">Manual Review Required</h1>
        <p className="pt-1 italic text-center">Review <b>non auto-completed</b> emails with lower confidence.</p>
        <p className="font-medium text-center mt-2"><b>{Math.max(totalEmailsToReview - emailsReviewed, 0)}</b> emails left to review</p>
  
        {noMoreEmails ? (
          <div className="mx-auto max-w-7xl w-full border rounded-lg shadow-lg shadow-inner p-8 mt-8">
            <p className="text-lg font-semibold text-center mb-6">
              <span role="img" aria-label="thumbs up" className="text-3xl align-middle mr-3">✅</span>
              No more emails to review
            </p>
            <img src="/assets/thumbsup.png" alt="No more emails" className="w-96 mx-auto" />
          </div>
        ) : (
          <>
            <div className="w-full border border-gray-400 hover:border-gray-600 rounded-lg shadow-lg shadow-inner p-8 mt-8">
              <h3 className="text-blue-800 text-xl font-semibold pt-4">Parsed email values</h3>
              <div>
                <p className="inline-block pt-1 italic bg-gray-100">
                  The below info is automatically parsed from emails using AI. Review, edit, delete and/or submit.
                </p>
              </div>
              {currentData.length > 0 && (
                <div className="py-3 flex items-start gap-4">
                  <div>
                    <p className="py-0.5"><strong>Email Name:</strong></p>
                    <p className="py-0.5"><strong>Email Subject:</strong></p>
                    <p className="py-0.5"><strong>Email Datetime:</strong></p>
                  </div>
                  <div>
                    <p className="py-0.5">{firstRow.email_name}</p>
                    <p className="py-0.5">{firstRow.email_subject}</p>
                    <p className="py-0.5">{firstRow.email_datetime}</p>
                  </div>
                  <div className="ml-4">
                    <p className="py-0.5"><strong>Company Name:</strong></p>
                    <p className="py-0.5"><strong>Company Type:</strong></p>
                  </div>
                  <div>
                    <p className="py-0.5"> {firstRow.company_name} </p>
                    <p className="py-0.5"> {firstRow.company_type} </p>
                  </div>
                </div>
              )}
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full text-left table-auto text-sm font-light">
                        <thead className="text-gray-800 font-medium">
                          <tr className="border-b transition duration-300 ease-in-out hover:bg-gray-200">
                            {currentData.length > 0 && Object.keys(currentData[0]).map((header, index) => (
                              <th key={index} className={`${["email_name", "email_subject", "email_datetime", "company_name", "company_type", "status", "id", "email_id", "completed_by", "completed_at"].includes(header) ? "hidden" : ""} 
                                ${index === 5 ? "min-w-[20rem] text-gray-500 text-base font-semibold bg-white text-center border border-white px-1 py-2" : "px-2 py-2 bg-gray-100"}`}>
                                {header}
                              </th>
                            ))}
                            <th className="px-2 py-2 bg-gray-100">Add/Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((row, index) => (
                            <tr className="transition duration-300 ease-in-out hover:bg-neutral-100" key={index}>
                              {Object.keys(row).map((column, cellIndex) => (
                                <td 
                                  key={cellIndex} 
                                  className={`${["email_name", "email_subject", "email_datetime", "company_name", "company_type", "status", "id", "email_id", "completed_by", "completed_at"].includes(column) ? "hidden" : ""} 
                                    ${cellIndex === 5 ? "bg-orange-100 rounded-full text-center font-semibold px-2 border-solid border-white" : "px-2 border-b"}`}
                                >
                                  {cellIndex === 5 ? (
                                    <span>{row[column]}</span>
                                  ) : (
                                    <input
                                      type="text"
                                      className="w-full"
                                      value={row[column]}
                                      onChange={(e) => handleCellChange(index, column, e.target.value)}
                                    />
                                  )}
                                </td>
                              ))}
                              <td className="px-2 py-2">
                                <button className="font-semibold text-blue-800" onClick={() => handleAddRow(index)}>+</button>
                                <span className="inline-block w-2"></span>
                                <button className="font-semibold text-blue-800" onClick={() => handleDeleteRow(index)}>-</button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="flex justify-end pt-4 mr-6">
              <button
                className={`font-semibold text-lg ${currentIndex <= 0 ? 'text-orange-100' : 'text-orange-400 hover:text-orange-500'}`}
                onClick={handlePrevious}
                disabled={currentIndex <= 0}
              >
                Previous
              </button>
              <span className="inline-block w-2"></span>
              <button
                className={`font-semibold text-lg ${currentIndex >= groupedData.length - 1 ? 'text-orange-100' : 'text-orange-400 hover:text-orange-500'}`}
                onClick={handleNext}
                disabled={currentIndex >= groupedData.length - 1}
              >
                Next
              </button>
              <span className="inline-block w-2"></span>
              <button
                className="font-semibold rounded-lg py-2 px-2 text-lg text-orange-400 hover:text-red-700"
                onClick={() => handleAction('delete')}
              >
                Delete
              </button>
              <span className="inline-block w-2"></span>
              <button
                className="font-semibold rounded-lg py-2 px-2 text-lg text-orange-400 hover:text-orange-500"
                onClick={() => handleAction('submit')}
              >
                Submit
              </button>
            </div>  
            <div className="flex flex-wrap -mx-3 mt-8 mb-8">
              <div className="w-1/2 px-3">
                <div className="max-h-160 min-h-160 overflow-auto border border-gray-400 hover:border-gray-600 rounded-lg shadow-lg shadow-inner p-8">
                  <h3 className="text-gray-400 text-xl font-semibold mb-2">Email Preview</h3>
                  <div>
                  </div>
                  <div className="pt-4 mb-1">
                    <EmailPreview 
                      emailId={firstRow.email_id} 
                      onAttachmentPreviewChange={setAttachmentPreview}
                    />
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-3">
                <div className="max-h-160 min-h-160 overflow-auto border border-gray-400 hover:border-gray-600 rounded-lg shadow-lg shadow-inner p-8">
                  <h3 className="text-gray-400 text-xl font-semibold mb-2">Attachment Preview</h3>
                  <div>
                  </div>
                  <div>
                    {firstRow.email_id && attachmentPreview ? (
                      attachmentPreview.type === 'pdf' ? (
                        <iframe src={attachmentPreview.url} width="100%" height="535px" frameBorder="0"></iframe>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: attachmentPreview.content }} />
                      )
                    ) : (
                      <p className="mt-2">No attachment found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );  
}

export default ManualReview;