import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

function EmailPreview({ emailId, onAttachmentPreviewChange }) {
  const [emailBody, setEmailBody] = useState('');

  const formatContent = (content) => {
    // Simple check to see if content is likely HTML
    if (content.includes('<') && content.includes('>')) {
      return content;  // Assume it's HTML and return as is
    } else {
      // Convert newlines to HTML line breaks for plain text
      return content.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
  };

  useEffect(() => {
    const fetchEmail = () => {
      if (emailId) {
        console.log('Fetching email for ID:', emailId);
        fetch(`http://localhost:4000/get-email/${emailId}`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            const formattedBody = formatContent(data.body_w_img || 'Body content not available');
            setEmailBody(formattedBody);

            if (!data.attachments) {
              onAttachmentPreviewChange(null);
              return;
            }
            
            console.log('attachmentType logic running');
            // Assuming data.attachments is the JSON returned from the backend
            const attachments = JSON.parse(data.attachments);

            if (attachments.pdf) {
              console.log('attachmentType includes pdf');
              const byteArray = Uint8Array.from(atob(attachments.pdf), c => c.charCodeAt(0));
              const blob = new Blob([byteArray], { type: 'application/pdf' });
              const blobUrl = URL.createObjectURL(blob);
              onAttachmentPreviewChange({ type: 'pdf', url: blobUrl });
            } else if (attachments.excel) {
                console.log('attachmentType includes excel');
                // Decode the base64-encoded HTML string
                const htmlContent = atob(attachments.excel);
                // Update the state to render the HTML content
                onAttachmentPreviewChange({ type: 'excel', content: htmlContent });
              }
          })
          .catch(error => {
            console.error('Error fetching email:', error);
            setEmailBody('Error fetching email');
            onAttachmentPreviewChange(null); // Ensure to handle errors
          });
      } else {
        setEmailBody('No email selected');
        onAttachmentPreviewChange(null);
      }
    };

    fetchEmail();

    return () => {
      onAttachmentPreviewChange(null); // Clean up function
    };
  }, [emailId, onAttachmentPreviewChange]); // Dependency array

  return (
    <div dangerouslySetInnerHTML={{ __html: emailBody }} />
  );
}

export default EmailPreview;
