import React, { createContext, useState, useEffect } from 'react';

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [totalEmailsToReview, setTotalEmailsToReview] = useState(() => parseInt(localStorage.getItem('totalEmailsToReview'), 10) || 0);
  const [emailsReviewed, setEmailsReviewed] = useState(() => parseInt(localStorage.getItem('emailsReviewed'), 10) || 0);

  // Update localStorage when state changes
  useEffect(() => {
    localStorage.setItem('totalEmailsToReview', totalEmailsToReview);
    localStorage.setItem('emailsReviewed', emailsReviewed);
  }, [totalEmailsToReview, emailsReviewed]);

  return (
    <StateContext.Provider value={{ totalEmailsToReview, setEmailsReviewed, emailsReviewed, setTotalEmailsToReview }}>
      {children}
    </StateContext.Provider>
  );
};