import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';

function PricingPage() {
  return (
    <Layout>
      <div className="container mx-auto p-5 w-full md:max-w-1/2">
        <div className="bg-gray-200 bg-opacity-20 rounded-lg shadow-xl p-5 md:p-20">
            <h2 className="text-4xl font-semibold text-center text-gray-800 mb-8">Pricing</h2>
            <h3 className="text-2xl font-semibold mb-5">Tailored Solutions for Your Unique Needs</h3>
            <p className="mb-5">At <strong>TradeParser</strong>, we understand that each business is unique and has its own set of challenges and requirements. That's why we don't have a one-size-fits-all pricing model. Instead, we believe in providing personalized pricing plans that perfectly align with your specific needs.</p>
            
            <h4 className="text-xl font-semibold mb-4">Why Custom Pricing?</h4>
            <p><strong>Customized for You:</strong> Your business is not like any other. Our pricing reflects the bespoke nature of the solutions we offer, ensuring you pay for what you truly need.<br />
            <strong>Flexible and Fair:</strong> Our pricing structure is designed to be fair and transparent, with a combination of a fixed base rate and a variable portion based on the volume of data processed.<br />
            <strong>Value-Driven Approach:</strong> We focus on delivering value. The cost is directly related to the benefits and efficiencies you gain, ensuring a worthwhile investment for your business.</p>

            <h4 className="text-xl font-semibold pt-4">How Does It Work?</h4>
            <ol className="list-decimal pl-6 mb-5 marker:font-bold">
                <li><strong>Consultation</strong>: We start with a detailed discussion about your requirements, challenges, and goals.</li>
                <li><strong>Custom Proposal</strong>: Based on our consultation, we'll craft a tailored proposal outlining the pricing structure.</li>
                <li><strong>Transparent Quotations</strong>: You'll receive a clear and comprehensive quote.</li>
                <li><strong>Scalability</strong>: As your business grows, our pricing adapts.</li>
            </ol>

            <h4 className="text-xl font-semibold mb-4">Ready to Get Started?</h4>
            <p className="mb-5">While we don't display standard rates, we are committed to providing <strong>competitive and transparent pricing</strong> tailored to your specific needs.</p>
            <p className="mb-5"><Link to="/signup" className="text-blue-800 underline font-bold">Contact us</Link> today for a personalized quote!</p>
        </div>
      </div>
    </Layout>
  );
}

export default PricingPage;