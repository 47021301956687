import React, { useState, useEffect } from 'react';
import DashboardLayout from './DashboardLayout';

// Custom Chevron Up SVG Components
const ChevronUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15L12 11.25 8.25 15" />
  </svg>
);
const ChevronDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9L12 12.75 15.75 9" />
  </svg>
);

function SubmittedValues() {
  const [submittedData, setSubmittedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
  const [filters, setFilters] = useState({
    email_name: '',
    email_subject: '',
    email_datetime: '',
    action: '',
    completed_by: '',
    weight_kg: '',
    dm_fat: '',
    protein: '',
    order_nr: '',
    contract_nr: '',
    date: '',
    week: '',
    company_name: '',
    company_type: '',
    product: '',
  });

  useEffect(() => {
    fetch('http://localhost:4000/get-submitted-values')
      .then(response => response.json())
      .then(data => {
        const updatedData = data.map(item => {
          if (item.status === "ok" && item.action === "No action (auto completed)") {
            return { ...item, completed_by: "auto" };
          } else if (item.status === "ok") {
            return { ...item, completed_by: "manual" };
          }
          return item;
        });
        setSubmittedData(updatedData);
      })
      .catch(error => console.error('Fetch error:', error));
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableItems = [...submittedData];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [submittedData, sortConfig]);

  const filteredSortedData = sortedData.filter(row => {
    return Object.keys(filters).every(key => {
      const filterValue = filters[key].toLowerCase().trim(); // Trim and convert filter value to lowercase
      if (filterValue === '') return true; // If filter value is empty, include the row
      if (!row[key]) return false; // If the row value is empty, exclude the row
  
      // Convert row value to lowercase and check if it includes the filter value
      return String(row[key]).toLowerCase().includes(filterValue);
    });
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSortedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredSortedData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <DashboardLayout>
      <div className="mx-auto px-6">
        <h1 className="text-xl text-blue-800 text-center md:text-2xl font-bold pt-4 mb-4">Overview of Submitted Values</h1>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-left table-auto text-sm font-light">
                  <thead className="text-gray-800 font-medium">
                    <tr className="border-b bg-gray-100">
                      {Object.keys(filters).map(key => (
                        <th key={key} className="px-3 py-2 cursor-pointer" onClick={() => requestSort(key)}>
                          <div className="flex justify-between items-center w-full">
                            <span>{key.split('_').join(' ')}</span>
                            {sortConfig.key === key && (
                              sortConfig.direction === 'ascending' ? <ChevronUp /> : <ChevronDown />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                    <tr className="border-b bg-gray-200">
                      {Object.keys(filters).map(key => (
                        <th key={key} className="py-1">
                          <input
                            type="text"
                            name={key}
                            value={filters[key]}
                            onChange={handleFilterChange}
                            placeholder={`Filter`}
                            className="w-full font-normal text-sm p-1 border rounded"
                          />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map(row => (
                      <tr key={row.id} className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                        {Object.keys(filters).map(column => (
                          <td key={column} className="px-3 py-2">{row[column]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to <span className="font-medium">{indexOfLastItem}</span> of <span className="font-medium">{filteredSortedData.length}</span> results
                      </p>
                    </div>
                    <div>
                      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        {pageNumbers.map(number => (
                          <a onClick={() => paginate(number)} href="#!" className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-tight hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring ring-gray-300">
                            {number}
                          </a>
                        ))}
                      </nav>
                      <div className="flex justify-end">
                          <button className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition ease-in-out duration-150">
                              Export as CSV
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default SubmittedValues;
