import React, { useState, useEffect, useContext} from 'react'; 
import { StateContext } from './DashboardStateContext';
import axios from 'axios';
import DashboardLayout from './DashboardLayout';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    const { totalEmailsToReview, emailsReviewed } = useContext(StateContext);
    const emailsLeft = Math.max(totalEmailsToReview - emailsReviewed, 0);
    const [last7dStats, setLast7dStats] = useState({});
    const [parsed7dStats, setParsed7dStats] = useState({});
    const [emailAggStatus, setEmailAggStatus] = useState({});
    const percentageAutoSubmitted = parsed7dStats.emails_parsed > 0 
      ? ((parsed7dStats.emails_auto_submitted / parsed7dStats.emails_parsed) * 100).toFixed(0)
      : 0;
    const percentageAutoIgnored = parsed7dStats.emails_parsed > 0
      ? ((parsed7dStats.emails_auto_ignored / parsed7dStats.emails_parsed) * 100).toFixed(0)
      : 0;

    // Video control handlers that operate on the event's target (the video)
    const handleMouseEnter = (event) => {
        event.target.play();
    };

    const handleMouseLeave = (event) => {
        event.target.pause();
    };

    useEffect(() => {
      const fetchLast7dStats = () => {
        axios.get('http://localhost:4000/last-7d-stats').then(response => {
          setLast7dStats(response.data);
        }).catch(error => console.error('Error fetching last 7 days stats:', error));
      };
  
      const fetchParsed7dStats = () => {
        axios.get('http://localhost:4000/parsed-7d-stats').then(response => {
          setParsed7dStats(response.data);
        }).catch(error => console.error('Error fetching parsed 7 days stats:', error));
      };

      const fetchEmailAggStatus = () => {
        axios.get('http://localhost:4000/get-email-agg-status').then(response => {
            setEmailAggStatus(response.data);
        }).catch(error => console.error('Error fetching email aggregated stats'))
      }

      // Initial fetch
      fetchLast7dStats();
      fetchParsed7dStats();
      fetchEmailAggStatus();
  
      // Set intervals
      const interval1 = setInterval(fetchLast7dStats, 60000);
      const interval2 = setInterval(fetchParsed7dStats, 60000);
      const interval3 = setInterval(fetchEmailAggStatus, 60000);
  
      // Cleanup intervals on component unmount
      return () => {
        clearInterval(interval1);
        clearInterval(interval2);
        clearInterval(interval3);
      };
    }, []);

    return (
      <DashboardLayout>
          <div className="mx-auto p-20">
              <h1 className="text-3xl text-blue-800 text-center font-bold pt-4 mb-12">👋 Welcome back, Stijn!</h1>
              <div className="grid grid-flow-dense grid-rows-1 grid-rows-2 gap-x-16 gap-y-8 md:text-lg">
                  <div className="flex justify-end col-span-1 row-span-2">
                      <div className="flex flex-col w-[500px] border border-gray-200 rounded-lg shadow-lg pl-12 shadow-inner pt-3">
                          <p className="font-semibold pt-4">Emails scanned</p>
                          <p className="italic text-base">Last 7 days</p>
                          <div className="w-64 h-64 bg-no-repeat bg-center bg-transparent"
                            style={{ backgroundImage: "url('https://cdn-icons-png.flaticon.com/512/10971/10971756.png')", backgroundSize: '80%' }}>
                            <video 
                              className="w-full h-full" 
                              preload="none" 
                              loop 
                              muted 
                              playsInline
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onLoadedMetadata={(event) => { event.target.playbackRate = 0.7; }} // set playback rate when metadata is loaded
                            >
                            <source src="https://cdn-icons-mp4.flaticon.com/512/10971/10971756.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <p className="mb-1"><b>{parsed7dStats.emails_parsed}</b> emails scanned:</p>
                          <table className="">
                            <tr>
                                <td className="whitespace-nowrap text-center"><b>{parsed7dStats.emails_auto_submitted}</b></td>
                                <td>auto submitted ({percentageAutoSubmitted}%)</td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap text-center"><b>{parsed7dStats.emails_auto_ignored}</b></td>
                                <td>auto ignored ({percentageAutoIgnored}%)</td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap text-center"><b>{parsed7dStats.emails_manual_submitted}</b></td>
                                <td>manually submitted</td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap text-center"><b>{parsed7dStats.emails_manual_ignored}</b></td>
                                <td>manually ignored</td>
                            </tr>
                            <tr>
                                <td className="text-base whitespace-nowrap text-center"><Link to="/manual_review"><b>{parsed7dStats.emails_to_review}</b></Link></td>
                                <td className="font-semibold"><Link to="/manual_review">emails left to review</Link></td>
                            </tr>
                        </table>
                      </div>
                  </div>
                  <div className="flex justify-start col-span-1">
                      <div className="flex flex-row items-center w-[500px] border border-gray-200 rounded-lg shadow-lg shadow-inner">
                        <div className="w-64 h-64 bg-no-repeat bg-center bg-transparent pl-3 pt-3 pb-3"
                                style={{ backgroundImage: "url('https://cdn-icons-png.flaticon.com/512/10828/10828086.png')", backgroundSize: '60%' }}>
                            <video
                                className="w-full h-full" 
                                preload="none" 
                                loop 
                                muted 
                                playsInline
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onLoadedMetadata={(event) => { event.target.playbackRate = 0.7; }} // set playback rate when metadata is loaded
                            >
                                <source src="https://cdn-icons-mp4.flaticon.com/512/10828/10828086.mp4" type="video/mp4"></source>
                            </video>
                          </div>
                          <div className="flex flex-col justify-center text-left pl-4">
                              <Link to="/manual_review" className="font-semibold text-blue-800">Complete tasks</Link>
                              <br></br>
                              <p><b>{parsed7dStats.emails_to_review}</b> emails left to review {emailsLeft === 0 && <span role="img" aria-label="thumbs up" className="text-xl ml-1">✅</span>}</p>
                          </div>
                      </div>                      
                  </div>
                  <div className="flex justify-start col-span-1">
                      <div className="flex flex-row items-center w-[500px] border border-gray-200 rounded-lg shadow-lg shadow-inner">
                          <div className="w-64 h-64 bg-no-repeat bg-center bg-transparent pl-3 pt-3 pb-3"
                                style={{ backgroundImage: "url('https://cdn-icons-png.flaticon.com/512/9872/9872488.png')", backgroundSize: '70%' }}>
                            <video
                                className="w-full h-full" 
                                preload="none" 
                                loop 
                                muted 
                                playsInline
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onLoadedMetadata={(event) => { event.target.playbackRate = 0.7; }} // set playback rate when metadata is loaded
                            >
                                <source src="https://cdn-icons-mp4.flaticon.com/512/9872/9872488.mp4" type="video/mp4"></source>
                            </video>
                          </div>
                          <div className="flex flex-col justify-center text-left pl-4">
                              <Link to="/submitted_values" className="font-semibold text-blue-800">Analyze results</Link>
                              <p className="italic text-base">Last 7 days</p>
                              <br></br>
                              <p><b>{last7dStats.submitted_emails}</b> emails submitted</p>
                              <p><b>{last7dStats.submitted_rows}</b> values submitted (rows)</p>
                         </div>
                      </div>
                  </div>
                  <div className="mx-auto col-span-2 row-span-1 w-[1064px]">
                  <div className="flex flex-row grid grid-rows-2 items-center mx-auto border border-gray-200 rounded-lg shadow-lg shadow-inner p-4">
                    <div className="relative">
                        <p className="font-semibold pb-1">Overview emails scanned</p>
                        <p className="font-normal italic text-base">Last 7 days</p>
                            <div className="overflow-y-auto max-h-[300px]">
                                <table className="min-w-full text-left table-auto text-sm font-light scroll-smooth focus:scroll-auto">
                                    <thead className="text-gray-800 font-medium sticky top-0 bg-white">
                                        <tr>
                                            <th className="px-2 py-2">Status</th>
                                            <th className="px-2 py-2">Name</th>
                                            <th className="px-2 py-2">Subject</th>
                                            <th className="px-2 py-2">Datetime</th>
                                            <th className="px-2 py-2">Count Rows</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600">
                                        {emailAggStatus.length > 0 ? emailAggStatus.map((email, index) => {
                                            let statusColor = "text-gray-800"; // Default color

                                            if (['Auto submitted', 'Manually submitted', 'Mixed submitted', 'Mixed'].includes(email.email_status)) {
                                                statusColor = "text-green-500";
                                            } else if (['Auto ignored', 'Manually ignored', 'Mixed ignored'].includes(email.email_status)) {
                                                statusColor = "text-red-500";
                                            } else if (email.email_status === 'Review required') {
                                                statusColor = "text-yellow-400 font-semibold";
                                            }

                                            return (
                                                <tr key={index} className="border-b">
                                                    <td className={`px-2 py-2 ${statusColor}`}>{email.email_status}</td>
                                                    <td className="px-2 py-2">{email.email_name}</td>
                                                    <td className="px-2 py-2">{email.email_subject}</td>
                                                    <td className="px-2 py-2">{email.email_datetime}</td>
                                                    <td className="pl-4 py-2">{email.cnt_rows}</td>
                                                </tr>
                                            );
                                        }) : (
                                            <tr>
                                                <td colSpan="5" className="text-center py-4">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </DashboardLayout>
    );
};

export default Dashboard;
