import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import LoginButton from './LoginButton'; // Import LoginButton component

const Layout = ({ children }) => {
  const location = useLocation(); // Get the current location
  const canonicalUrl = `https://www.tradeparser.ai${location.pathname}`; // Construct the canonical URL

  useEffect(() => {
    // Set the class on body element
    document.body.className = 'bg-custom-orange font-mono text-base';

    // Optional: Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.className = '';
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="flex flex-col min-h-screen">
        <Helmet>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Trade Parser AI</title>
            <link rel="canonical" href={canonicalUrl} /> {/* Set the canonical URL dynamically */}
            {/* Favicon */}
            <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.PUBLIC_URL}/apple-touch-icon.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/favicon-32x32.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/favicon-16x16.png`} />
            <link rel="manifest" href={`${process.env.PUBLIC_URL}/site.webmanifest`} />
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-SZD3J65EMK"></script>
            <script 
                dangerouslySetInnerHTML={{ 
                __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-SZD3J65EMK');
                `
                }} 
            />    
        </Helmet>
        <header className="py-2">
            <div className="container mx-auto">
                <p className="text-center text-5xl font-vt323 text-[#5271ff] [text-shadow:_0_2px_0_rgb(31_41_55_/_18%)]">
                    TradeParser
                </p>
            </div>
            <div className="container mx-auto flex items-center justify-between">
                <nav className="flex w-full justify-between">
                    <ul className="flex text-center">
                        <li className={`${location.pathname === '/' ? 'font-bold underline' : ''}`}>
                            <Link to="/" className="block px-4 py-2">HOME</Link>
                        </li>
                        <li className={`${location.pathname === '/about' ? 'font-bold underline' : ''}`}>
                            <Link to="/about" className="block px-4 py-2">ABOUT</Link>
                        </li>
                        <li className={`${location.pathname === '/blog' ? 'font-bold underline' : ''}`}>
                            <Link to="/blog" className="block px-4 py-2">BLOG</Link>
                        </li>
                        <li className={`${location.pathname === '/pricing' ? 'font-bold underline' : ''}`}>
                            <Link to="/pricing" className="block px-4 py-2">PRICING</Link>
                        </li>
                    </ul>
                    <ul className="flex">
                        <li className={`${location.pathname === '/login' ? 'bg-blue-500 rounded-lg font-bold underline text-white' : ''}`}>
                            <LoginButton />
                        </li>
                        <li className={`${location.pathname === '/signup' ? 'bg-blue-500 rounded-lg font-bold underline text-white ml-4' : 'ml-4'}`}>
                            <Link to="/signup" className="block px-4 py-2 rounded-lg border border-blue-500 hover:bg-blue-600 hover:text-white">Try it now!</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <main className="flex-grow">{children}</main>
        <footer className="w-full fixed bottom-0 flex justify-between items-center px-4 py-2 bg-custom-light-orange text-gray-500">
            <p className="ml-4 text-xs">© Copyright - TradeParser</p>
            <p className="mr-4 text-xs">Contact: stijn@tradeparser.ai</p>
        </footer>
    </div>
  );
};

export default Layout;
