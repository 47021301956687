import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';

function AboutPage() {
  return (
    <Layout>
      <div className="container mx-auto p-5">
        <div className="bg-gray-200 bg-opacity-20 rounded-lg shadow-xl p-5 md:p-20">
          <p className="text-sm sm:text-xl leading-normal pt-1">
          Hi <span role="img" aria-label="waving hand">👋</span>, I'm Stijn, the founder of TradeParser. You can follow me on{' '}
            <a
              href="https://www.linkedin.com/in/stijnzweegers/"
              className="text-blue-800 underline font-semibold"
            >
              LinkedIn
            </a>{' '}
            and{' '}
            <a
              href="https://twitter.com/StijnZweegers"
              className="text-blue-800 underline font-semibold"
            >
              Twitter
            </a>{' '}
            for insights and updates.
          </p>

          <p className="text-sm sm:text-xl leading-normal pt-3.5">
            My passion for leveraging data to make business decisions has been the foundation of
            my career. I've spent significant time in the tech and logistics sectors, most recently
            spending 6 years at Stripe where I led a team of data scientists and analysts, tackling
            complex data challenges from fraud prevention to enhancing user experiences with Machine
            Learning and AI.
          </p>

          <p className="text-sm sm:text-xl leading-normal pt-3.5">
            As the founder of tradeparser.ai, my goal is to simplify processing for trading companies
            by effectively parsing trading data with AI and thereby increasing productivity and
            profitability. My experience in solving challenging data problems over the past decade
            has proven to be invaluable to our customers so far.</p>
          {/* Closing tag added here */}
          <p className="text-sm sm:text-xl leading-normal pt-3.5">
            <Link to="/signup" className="text-blue-800 underline font-semibold">
              Let's connect</Link> and transform the way your business interacts with trade data!
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default AboutPage;
